//
const trans = {
  en: {},
  de: {
    /*events and rests info*/
    event_in_the_past: "Veranstaltung liegt in der Vergangenheit",
    info_about_event: "Informationen zur Veranstaltung",
    info_about_rest: "Infos zum Restaurant",
    route: "Routenplaner",
    location: "Standort",
    events: "Aktionen",
    videoblogs: "Videoblogs",
    menu: "Menü",
    description: "Beschreibung",
    /*schedule*/
    schedule_closed: "Geschlossen",
    schedule_open: "Geöffnet",
    /*buttons*/
    btn_order_delivery: "Jetzt bestellen",
    btn_book_table: "Tisch Reservieren",
    btn_weekly_menu: "Wochenkarte",
    btn_show_all: "Alle anzeigen",
    btn_show_all_events: "Alle Aktionen anzeigen",
    btn_show_all_blogs: "Alle Posts ansehen",
    btn_watch_all_videos: "Alle Videos ansehen",
    btn_events: "Aktionen und Events",
    btn_blog: "Blog",
    btn_recipe: "Rezept",
    btn_videoblog: "Videoblog",
    btn_view_details: "Details ansehen",
    btn_website: "Zur Webseite",
    btn_new_request: "Neue Anfrage",
    /*others*/
    area: "Größe",
    persons: "Personen",
    back: "Zurück",
    stories_recommendations: "Die Sifi-Eats Empfehlungen",
    reserve_form_title: "Tischreservierung",
    reserve_form_text: "Gerne können Sie das Formular für die Tischreservierung verwenden. Ihr Wunsch wird dann an den Restaurantbesitzer weitergeleitet. Die Rückmeldung erfolgt direkt durch das Restaurantpersonal. Das Ausfüllen und Abschicken des Formulars bedeutet die Akzeptanz unserer Datenschutzerklärung.",
  },
}
const translate = (text, lang) => {
  const l = lang || 'de'
  return trans[l][text] || text
}
export default translate