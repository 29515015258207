import * as React from 'react'
import { Link, graphql } from 'gatsby'

export const Footer = ({ footer }) => {
  console.log('footer: ', footer)
  const {copyright,footer_links,footer_logo,partners,partners_title} = footer.data;

  const footerLinks = footer
    ? footer_links.map((footerLink, index) => (
      <a key={index} href={footerLink.link.url} className="col-12 col-md-6 col-lg-4 text-decoration-none">{footerLink.label}</a>
      ))
    : null

  const partnerLogos = footer
    ? partners.map((partnerLogo, index) => (
      <div key={index} className="col-12 col-sm-6 col-md-3"><img className="" height="40" width="auto" src={partnerLogo.partner_logo.url} alt={"Partnerlogo  " + (index+1)} /></div>
      ))
    : null

  return (
    <footer className="mt-auto">
      <div className="container py-4">
        <div className="row py-4 g-2">
          <div className="col-12 col-md-4 col-lg-3">      
            <a href={"/"}><img className="" height="40" width="auto" src={footer_logo.url} alt={"Sifi-Eats Fußzeilenlogo"}/></a>
          </div>
          <div className="col-12 col-md-8 col-lg-9">
            <div className="row g-2">
              {footerLinks}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div className="container py-4">
        <div className="text-center">{partners_title.text}</div>
          <div className="row g-2 text-center my-3">
            {partnerLogos}
          </div>
        <div className="text-center">{copyright.text}</div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          url
        }
      }
      footer_logo {
        url
      }
      partners {
        partner_logo {
          url
        }
      }
      partners_title {
        richText
        text
      }
    }
  }
`