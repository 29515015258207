import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { Footer } from './Footer'

const JobPosting = (jobData) => {
  if (!jobData) return {}
  if (!Object.keys(jobData).length) return {}

  const title = jobData.title
  const datePosted = jobData.date
  const description = jobData.desc
  
  const org = {
    name: jobData.organization.name,
    url: jobData.organization.url,
    logo: jobData.organization.logo,
  }

  const loc = {
    locality: jobData.location.locality,
    region: jobData.location.region,
    country: jobData.location.country,
  }

  const jobSchema = {
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    title,
    datePosted,
    description,
    hiringOrganization: {
      "@type": "Organization",
      "name": org.name,
    },
    jobLocation: {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": loc.locality,
        "addressRegion": loc.region,
        "addressCountry": loc.country,
      }
    },
  }

  if (org.url) {
    jobSchema.hiringOrganization["sameAs"] = org.url
  }
  if (org.logo) {
    jobSchema.hiringOrganization["logo"] = org.logo
  }

  const {baseSalary} = jobData
  if (baseSalary) {
    const currency = baseSalary.currency || "USD"
    jobSchema.baseSalary = {
      "@type": "MonetaryAmount",
      currency,
      "value": {
        "@type": "QuantitativeValue",
        "value": baseSalary.value,
        "unitText": baseSalary.unit,
      }
    }
  }
  return jobSchema
}

export const Layout = ({ children, topMenu, footer, seo, job }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
          metaImage
        }
      }
    }
  `)

  const lang = 'de'
  const title = seo.title || queryData.site.siteMetadata.title
  const metaDescription = seo.description || queryData.site.siteMetadata.description
  const keywords = seo.keywords || queryData.site.siteMetadata.keywords
  
  const meta = [{
      name: `description`,
      content: metaDescription,
    },{
      name: "keywords",
      content: keywords,
    },{
      property: `og:title`,
      content: title,
    },{
      property: `og:description`,
      content: metaDescription,
    },{
      property: `og:type`,
      content: `website`,
    },{
      name: `twitter:creator`,
      content: queryData.site.siteMetadata.author,
    },{
      name: `twitter:title`,
      content: title,
    },{
      name: `twitter:description`,
      content: metaDescription,
    },
  ]
  const noRobots = seo.no_robots !== undefined && seo.no_robots === true
  if (noRobots) {
    meta.push({
      name: "robots",
      content: "noindex",
    })
  }

  let _metaImage = [{
      name: "twitter:card",
      content: "summary",
    },
  ]
  const {siteUrl} = queryData.site.siteMetadata
  const metaImage = seo.metaImage || queryData.site.siteMetadata.metaImage
  console.log("metaImage ", metaImage)

  if (metaImage) {
    const image = metaImage.startsWith('http') ? `${metaImage}` : `${siteUrl}${metaImage}`
    _metaImage = [{
        property: "og:image",
        content: image,
      },{
        property: "og:image:width",
        content: metaImage.width,
      },{
        property: "og:image:height",
        content: metaImage.height,
      },{
        name: "twitter:card",
        content: "summary_large_image",
      },
    ]
  }

  const jobSchema = JobPosting(job)
  console.log('jobSchema: ', jobSchema)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          ...meta,
        ].concat(
          _metaImage
        )}
      >
      {Object.keys(jobSchema).length && (
        <script type="application/ld+json">
          {JSON.stringify(jobSchema)}
        </script>
      )}
      </Helmet>
      <section style={{display:"flex",minHeight:"100vh",flexDirection:"column",}}>
        {topMenu && <TopMenu topMenu={topMenu} activeDocMeta={{}} />}
        <main>{children}</main>
        {footer && <Footer footer={footer}/>}
      </section>
    </>
  )
}
